/* 
::-webkit-scrollbar {
 width:1
}

.scrollhost ::-moz-scrollbar {
  display: none;
 
}

.scrollhost {
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-color: transparent transparent;
}  */

.gantt_task_line .gantt_task_content {
  
  overflow: visible;
}

.gantt_task_line{
  border-color: rgba(0, 0, 0, 0.25);
}
.gantt_task_line .gantt_task_progress {
  background-color: rgba(0, 0, 0, 0.25);
}

/* high */
.gantt_task_line.high {
  background-color: #ff2c28;
}
.gantt_task_line.nhapnhay {
  background-color: #ff2c28;
  animation: blinker 1s linear infinite;
}

.gantt_task_line.high .gantt_task_content {
  color: #fff;
}

.gantt_task_line.wgdf {
  background-color: #fff;
  display: none;
}
.gantt_task_line.wgdf .gantt_task_content {
  color: #fff;
}


/* medium */
.gantt_task_line.medium {
  background-color: #9abcdb;
}
.gantt_task_line.medium .gantt_task_content {
  color: #fff;
}

/* low */
.gantt_task_line.low {
  background-color: #24c58c;
}
.gantt_task_line.low .gantt_task_content {
  color: #fff;
}


@keyframes blinker {
  0%{
    opacity: 0.2;
  }
  50%{
    opacity: 0.6;
  }
   100% {
    opacity: 1;
  } 
 
}

.gantt-error div {
  display: none;
}
.gantt-error {
  display: none;
}

.gantt_grid_data .gantt_cell {
  overflow: initial;
}